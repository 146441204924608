import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const IosBottomNavigation = (props: { children }) => {
  return (
    <>
      <div className="h-[48px]"></div>
      <nav className="fixed bottom-0 left-0 right-0 z-40">
        <div className="max-w-4xl mx-auto">
          <div
            className={
              "h-[48px] flex justify-between items-center backdrop-blur bg-gray-50/75 dark:bg-gray-900/75 border-t border-gray-200 dark:border-gray-50/[0.06]"
            }
          >
            {props.children}
          </div>
        </div>
      </nav>
    </>
  );
};

export default IosBottomNavigation;
