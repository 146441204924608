import { useEffect, useRef, useState } from "react";

const IosNavbar = (props: {
  children?;
  title;
  small?: boolean;
  titleSize?;
  leftAction?;
  rightAction?;
}) => {
  const [offset, setOffset] = useState(0);
  const header = useRef(null);
  const titleSize = props.titleSize ? props.titleSize : "text-3xl";

  useEffect(() => {
    const onScroll = () => {
      setOffset(window.pageYOffset);
    };
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      <div className="header" ref={header}>
        <div className="fixed top-0 left-0 right-0 z-40">
          <div className="max-w-4xl mx-auto">
            <div className="safe-area-inset-top bg-[#000000]"></div>
            <div
              className={`h-[44px] border-b flex justify-between items-center backdrop-blur bg-gray-100/75 dark:bg-gray-900/75 ${
                (props.small ? offset > 12 : offset > 37)
                  ? "border-gray-200 dark:border-gray-50/[0.06]"
                  : `border-transparent`
              }`}
            >
              <div className="flex-1 pr-3 flex justify-start text-lg text-sky-500 hover:text-sky-600 dark:text-sky-500 dark:hover:text-sky-400">
                <div className="pl-6">{props.leftAction}</div>
              </div>
              <div
                className={`transition flex justify-center text-lg text-gray-900 dark:text-gray-200 font-semibold overflow-hidden ${
                  props.small || offset > 37 ? "opacity-100" : "opacity-0"
                }`}
              >
                <div className="truncate">{props.title}</div>
              </div>
              <div className="flex-1 pl-3 flex justify-end text-lg text-lg text-sky-500 hover:text-sky-600 dark:text-sky-500 dark:hover:text-sky-400">
                <div className="pr-6">{props.rightAction}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={`h-[44px] ${props.small ? "mb-3" : ""}`}></div>
        {!props.small && (
          <h1
            className={`transition ${titleSize} py-2 px-6 font-bold text-gray-900 dark:text-gray-200 sm:truncate ${
              false ? "opacity-0" : "opacity-100"
            }`}
          >
            {props.title}
          </h1>
        )}
        {props.children}
      </div>
    </>
  );
};

export default IosNavbar;
