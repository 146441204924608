import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthProvider from "./components/AuthProvider";
import AuthRequired from "./components/AuthRequired";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Layout from "./pages/Layout";
import Books from "./pages/Books";
import Account from "./pages/account/Account";
import Appearance from "./pages/account/Appearance";
import EditAccount from "./pages/account/EditAccount";
import Admin from "./pages/admin/Admin";
import LandingPage from "./pages/landing/LandingPage";
import DataProvider from "./components/DataProvider";
import BookEditNew from "./pages/BookEditNew";
import Book from "./pages/Book";
import VocabularyEditNew from "./pages/VocabularyEditNew";
import Boxes from "./pages/Boxes";
import Box from "./pages/Box";

const App = () => {
  return (
    <>
      <AuthProvider>
        <Routes>
          <Route
            path="/"
            element={
              <AuthRequired>
                <DataProvider>
                  <Layout />
                </DataProvider>
              </AuthRequired>
            }
          >
            <Route index element={<Navigate to="/books" replace />} />
            <Route path="/boxes" element={<Boxes />} />
            <Route path="/boxes/:boxIndex" element={<Box />} />
            <Route path="/books" element={<Books />} />
            <Route path="/new-book" element={<BookEditNew />} />
            <Route path="/books/:bookId" element={<Book />} />
            <Route path="/books/:bookId/edit" element={<BookEditNew />} />
            <Route path="/books/:bookId/new-vocabulary" element={<VocabularyEditNew />} />
            <Route path="/books/:bookId/vocabularies/:vocabularyId" element={<VocabularyEditNew />} />
            <Route path="/account" element={<Account />} />
            <Route path="/account/edit" element={<EditAccount />} />
            <Route path="/account/appearance" element={<Appearance />} />
            <Route path="/admin" element={<Admin />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/landingpage" element={<LandingPage />} />
        </Routes>
      </AuthProvider>
    </>
  );
};

export default App;
