import React, { useEffect } from "react";
import { findAllData } from "../business/api/me-service";
import AccountDetails from "../business/dto/account-details";
import BookDetails from "../business/dto/book-details";
import Data from "../business/dto/data";
import { DataContext } from "../contexts/data-context";

export default function DataProvider(props: { children: React.ReactNode }) {
  const [data, setData] = React.useState<Data>();

  useEffect(() => {
    findAllData().then((data: Data) => {
      // data.books.sort((a, b) => +new Date(b.date) - +new Date(a.date));
      setData(data);
    });
  }, []);

  const setAccount = (account: AccountDetails): void => {
    setData({ ...data, account });
  };

  const setBooks = (books: BookDetails[]): void => {
    // posts.sort((a, b) => +new Date(b.date) - +new Date(a.date));
    setData({ ...data, books });
  };

  return (
    <DataContext.Provider
      value={{ data, setAccount, setBooks }}
    >
      {props.children}
    </DataContext.Provider>
  );
}
