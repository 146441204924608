import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const IosBottomContext = (props: { leftAction?; description?; rightAction? }) => {
  return (
    <>
      <div className="h-[48px]"></div>
      <nav className="fixed bottom-[48px] left-0 right-0 z-40">
        <div className="max-w-4xl mx-auto">
          <div
            className={`h-[44px] border-t flex justify-between items-center backdrop-blur bg-gray-50/75 dark:bg-gray-900/75 border-gray-200 dark:border-gray-50/[0.06]`}
          >
            <div className="flex-1 pr-3 flex justify-start text-lg text-sky-500 hover:text-sky-600 dark:text-sky-500 dark:hover:text-sky-400">
              <div className="pl-6">{props.leftAction}</div>
            </div>
            <div
              className={`transition flex justify-center text-xs text-gray-900 dark:text-gray-200 font-medium overflow-hidden`}
            >
              <div className="truncate">{props.description}</div>
            </div>
            <div className="flex-1 pl-3 flex justify-end text-lg text-lg text-sky-500 hover:text-sky-600 dark:text-sky-500 dark:hover:text-sky-400">
              <div className="pr-6">{props.rightAction}</div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default IosBottomContext;
