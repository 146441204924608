import React from "react";
import AccountDetails from "../business/dto/account-details";
import BookDetails from "../business/dto/book-details";
import Data from "../business/dto/data";

interface DataContextType {
  data: Data;
  setAccount: (account: AccountDetails) => void;
  setBooks: (books: BookDetails[]) => void;
}

export const DataContext = React.createContext<DataContextType>(null!);