import { Link, NavLink, useMatch, useResolvedPath } from "react-router-dom";

export default function IosBottomNavigationItem(props: {
  label: string;
  to: string;
  end: boolean;
  icon: React.FC<{ className: string }>;
  iconActive: React.FC<{ className: string }>;
  activeClass?: string
}) {
  let resolved = useResolvedPath(props.to);
  let match = useMatch({ path: resolved.pathname, end: props.end });

  return (
    <>
      <Link
        className="flex flex-col justify-center items-center w-full h-full"
        to={props.to}
        replace={true}
      >
        {!match && (
          <props.icon className="transition flex-shrink-0 h-6 w-6 text-gray-400 dark:text-gray-500" />
        )}
        {match && (
          <props.iconActive className={`transition flex-shrink-0 h-6 w-6 ${props.activeClass}`} />
        )}
        <div
          className={`transition text-xs ${
            match ? props.activeClass : "text-gray-400 dark:text-gray-500"
          }`}
        >
          {props.label}
        </div>
      </Link>
    </>
  );
}
