import React, { useEffect, useState } from "react";
import { findAllUsers } from "../../business/api/user-service";
import UserRef from "../../business/dto/user-ref";
import IosListGroup from "../../components/ios/IosListGroup";
import IosListGroupItem from "../../components/ios/IosListGroupItem";
import IosNavbar from "../../components/ios/IosNavbar";
import IosSearch from "../../components/ios/IosSearch";

export default function Admin() {
  const [users, setUsers] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    findAllUsers().then((users: UserRef[]) => {
      setUsers(users);
    });
  }, []);

  const filter = (users: UserRef[]) => {
    return users.filter(
      (user: UserRef) =>
        user.username.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase())
    );
  };

  return (
    <>
      <IosNavbar title={`Users ${users ? `(${filter(users).length})` : ""}`}>
        {
          <IosSearch
            placeholder="Search users"
            search={search}
            setSearch={setSearch}
          />
        }
      </IosNavbar>

      {users && filter(users).length > 0 && (
        <IosListGroup>
          {React.Children.toArray(
            filter(users).map((user) => (
              <IosListGroupItem
                key={user.id}
                title={user.username}
                subtitle={`@${user.email}`}
                img="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              />
            ))
          )}
        </IosListGroup>
      )}
    </>
  );
}
