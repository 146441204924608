import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  createBook,
  deleteBook,
  updateBook,
} from '../business/api/book-service'
import BookDetails from '../business/dto/book-details'
import BookProperties from '../business/dto/book-properties'
import IosNavbar from '../components/ios/IosNavbar'
import useData from '../contexts/data-auth'

export default function BookEditNew() {
  const [changes, setChanges] = useState<BookProperties>({
    title: '',
    author: '',
  })
  const navigate = useNavigate()
  const { bookId } = useParams()
  const { data, setBooks } = useData()

  useEffect(() => {
    if (bookId) {
      const book = data.books.find((post) => post.id === bookId)
      setChanges({
        title: book.title,
        author: book.author,
      })
    }
  }, [])

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setChanges({ ...changes, [e.target.name]: e.target.value })
  }

  const onActionClicked = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (bookId) {
      updateBook(bookId, changes).then((response: BookDetails) => {
        const index = data.books.findIndex((post) => post.id === bookId)
        data.books.splice(index, 1, response)
        setBooks(data.books)
        navigate(`/books/${response.id}`, { replace: true })
      })
    } else {
      createBook(changes).then((response: BookDetails) => {
        setBooks([...data.books, response])
        navigate(`/books/${response.id}`, { replace: true })
      })
    }
  }

  const onDelete = () => {
    deleteBook(bookId).then(() => {
      const index = data.books.findIndex((book) => book.id === bookId)
      if (index) {
        data.books.splice(index, 1)
      }
      navigate('/books', { replace: true })
    })
  }

  return (
    <form onSubmit={onActionClicked}>
      {changes && (
        <>
          <IosNavbar
            title={bookId ? 'Edit Book' : 'New Book'}
            leftAction={
              <Link to={bookId ? `/books/${bookId}` : '/books'} replace={true}>
                Cancel
              </Link>
            }
            rightAction={
              <button type="submit" className="font-normal">
                Done
              </button>
            }
          />

          <div className="bg-white dark:bg-gray-800 mb-6">
            <div className="w-full border-b border-gray-200 dark:border-gray-50/[0.06]"></div>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-6 flex items-center pointer-events-none">
                <span className="text-gray-400 dark:text-gray-500">Titel:</span>
              </div>
              <input
                type="text"
                name="title"
                id="title"
                className="block w-full dark:bg-gray-800 dark:text-gray-200 border-none focus:ring-0 pl-[78px] pr-6 py-3"
                value={changes.title}
                onChange={handleInputChange}
                autoFocus
              />
            </div>
            <div className="pl-6">
              <div className="w-full border-b border-gray-200 dark:border-gray-50/[0.06]"></div>
            </div>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-6 flex items-center pointer-events-none">
                <span className="text-gray-400 dark:text-gray-500">
                  Author:
                </span>
              </div>
              <input
                type="text"
                name="author"
                id="author"
                className="block w-full dark:bg-gray-800 dark:text-gray-200 border-none focus:ring-0 pl-[96px] pr-6 py-3"
                value={changes.author}
                onChange={handleInputChange}
              />
            </div>
            <div className="w-full border-b border-gray-200 dark:border-gray-50/[0.06]"></div>
          </div>

          {bookId && (
            <ul
              role="list"
              className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-50/[0.06] rounded-lg mx-3 mb-6 overflow-hidden"
            >
              <li>
                <button
                  className="relative w-full py-2 px-3 flex items-center justify-center hover:bg-gray-200 dark:hover:bg-slate-700 focus-within:ring-2 focus-within:ring-inset focus-within:ring-teal-600"
                  onClick={onDelete}
                >
                  <span className="absolute inset-0" aria-hidden="true" />
                  <div className="text-red-600">Delete</div>
                </button>
              </li>
            </ul>
          )}
        </>
      )}
    </form>
  )
}
