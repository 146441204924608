import { ChevronRightIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { Link } from 'react-router-dom'
import IosDivider from './IosDivider'

export default function IosListGroupItem(props: {
  title: string
  subtitle?: string
  img?: string
  icon?: React.FC<{ className: string }>
  iconColor?: string
  extra?: string
  to?: string
  action?: (e: React.MouseEvent<HTMLButtonElement>) => void
}) {
  let component

  /*
  if (props.to) {
    component = { tag: Link, props: { to: props.to, replace: true } };
  } else if (props.action) {
    component = { tag: JSX.IntrinsicElements.button, props: { to: props.to, replace: true } };
  }
  */

  return (
    <li className="relative group">
      <div className="flex items-center">
        {props.img && (
          <div className="flex-shrink-0 pl-3">
            <img
              className="h-10 w-10 rounded-full"
              src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
          </div>
        )}
        {props.icon && (
          <div className="flex-shrink-0 pl-3">
            <span
              className={`inline-flex items-center justify-center h-6 w-6 rounded-md ${props.iconColor}`}
            >
              <props.icon className="h-4 w-4 text-white" aria-hidden="true" />
            </span>
          </div>
        )}
        <div className={`relative flex-1 min-w-0 ${props.icon ? 'ml-3' : 'ml-3'}`}>
          <div className="group-first:hidden absolute right-0 bottom-0 left-0">
            <IosDivider />
          </div>
          <div className="flex items-center min-h-[44px] pr-3 w-full">
            <div className="flex-1 py-2 overflow-hidden">
              <div className="text-gray-900 dark:text-gray-200">
                {props.title}
              </div>
              {props.subtitle && (
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {props.subtitle}
                </p>
              )}
            </div>
            {(props.extra || props.to) && (
              <div className="flex-shrink-0 flex items-center pl-3">
                {props.extra && (
                  <div className="text-gray-500 dark:text-gray-400 mr-1">
                    {props.extra}
                  </div>
                )}
                {props.to && (
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400 dark:text-gray-500"
                    aria-hidden="true"
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {props.to && (
          <Link
            to={props.to}
            replace={true}
            className="absolute inset-0 z-10"
            aria-hidden="true"
          />
        )}
        {props.action && (
          <button
            onClick={props.action}
            className="absolute inset-0"
            aria-hidden="true"
          />
        )}
      </div>
    </li>
  )
}

/*

return (
    <li className="relative group">
      <div className="flex items-center hover:bg-gray-200 dark:hover:bg-slate-700 focus-within:ring-2 focus-within:ring-inset focus-within:ring-teal-600">
        {props.img && (
          <div className="flex-shrink-0 ml-3">
            <img
              className="h-10 w-10 rounded-full"
              src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
          </div>
        )}
        {props.icon && (
          <div className="flex-shrink-0 pl-3">
            <span
              className={`inline-flex items-center justify-center h-6 w-6 rounded-md ${props.iconColor}`}
            >
              <props.icon className="h-4 w-4 text-white" aria-hidden="true" />
            </span>
          </div>
        )}
        <div className="relative flex-1 pl-3 min-w-0">
          <div className="group-first:hidden group-hover:hidden absolute right-0 bottom-0 left-0">
            <IosDivider />
          </div>
          <div className="flex items-center pr-3 w-full">
            <div className="flex-1 py-2 min-h-[44px] overflow-hidden">
              <div className="text-gray-900 dark:text-gray-200 truncate">
                {props.title}
              </div>
              {props.subtitle && (
                <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
                  {props.subtitle}
                </p>
              )}
            </div>
            <div className="flex-shrink-0 flex items-center">
              {props.extra && (
                <div className="text-gray-500 dark:text-gray-400 mr-1">
                  {props.extra}
                </div>
              )}
              {props.to && (
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400 dark:text-gray-500 group-hover:text-gray-500 dark:group-hover:text-slate-400"
                  aria-hidden="true"
                />
              )}
            </div>
          </div>
        </div>
        {props.to && (
          <Link
            to={props.to}
            className="absolute inset-0 z-10"
            aria-hidden="true"
          />
        )}
        {props.action && (
          <button
            onClick={props.action}
            className="absolute inset-0"
            aria-hidden="true"
          />
        )}
      </div>
    </li>
  );

*/
