import axios from 'axios'
const ressourceUrl = '/api/translator'

export const translate = async (
  text: string,
  sourceLang: "de" | "en",
  targetLang: "de" | "en-US",
): Promise<{ translation: string }> => {
  return axios
    .post(ressourceUrl, { text, sourceLang, targetLang })
    .then((response) => response.data)
}
