import axios from "axios";
import Login from "../dto/login";
import UserRef from "../dto/user-ref";

const ressourceUrl = "/auth";

export const obtainToken = async (data: Login): Promise<any> => {
  return axios.post(`${ressourceUrl}/token`, data).then(response => response.data);
};

export const loadUserInfo = async (): Promise<UserRef> => {
  return axios.get(`${ressourceUrl}/userinfo`).then(response => response.data);
};