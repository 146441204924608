import axios from "axios";
import BookProperties from "../dto/book-properties";
import VocabularyDetails from "../dto/vocabulary-details";
import VocabularyProperties from "../dto/vocabulary-properties";

const ressourceUrl = "/api/vocabularies";

export const updateVocabulary = async (id: string, data: VocabularyProperties): Promise<VocabularyDetails> => {
  return axios.put(`${ressourceUrl}/${id}`, data).then((response) => response.data);
};

export const deleteVocabulary = async (id: string): Promise<any> => {
  return axios.delete(`${ressourceUrl}/${id}`).then((response) => response.data);
};