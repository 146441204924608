import axios from "axios";
import BookDetails from "../dto/book-details";
import BookProperties from "../dto/book-properties";
import VocabularyDetails from "../dto/vocabulary-details";
import VocabularyProperties from "../dto/vocabulary-properties";

const ressourceUrl = "/api/books";

export const createBook = async (data: BookProperties): Promise<BookDetails> => {
  return axios.post(ressourceUrl, data).then((response) => response.data);
};

export const updateBook = async (id: string, data: BookProperties): Promise<BookDetails> => {
  return axios.put(`${ressourceUrl}/${id}`, data).then((response) => response.data);
};

export const deleteBook = async (id: string): Promise<any> => {
  return axios.delete(`${ressourceUrl}/${id}`).then((response) => response.data);
};

export const createVocabulary = async (id: string, data: VocabularyProperties): Promise<VocabularyDetails> => {
  return axios.post(`${ressourceUrl}/${id}/vocabularies`, data).then((response) => response.data);
};