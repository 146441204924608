import axios from "axios";
import Registration from "../dto/registration";
import UserRef from "../dto/user-ref";

const ressourceUrl = "/api/users";

export const createUser = async (data: Registration): Promise<UserRef> => {
  return axios.post(ressourceUrl, data).then((response) => response.data);
};

export const findAllUsers = async (): Promise<UserRef[]> => {
  return axios.get(ressourceUrl).then((response) => response.data);
};

export const updateUser = async (
  id: string,
  lastName: string
): Promise<UserRef> => {
  return axios
    .put(`${ressourceUrl}/${id}`, { lastName })
    .then((response) => response.data);
};

export const deleteUser = async (id: string): Promise<any> => {
  return axios
    .delete(`${ressourceUrl}/${id}`)
    .then((response) => response.data);
};
